import layout from '@/layout'

export default {
  path: '/sys',
  component: layout,
  children: [
    {
      path: '/sys/station',
      component: () => import('@/views/sys/station')
    },
    {
      path: '/sys/asset',
      component: () => import('@/views/sys/asset')
    }
  ]
}
