<template>
  <el-drawer :model-value="modelValue" @close="closedClick" size="450px">
    <template #header>
      <h4>系统消息</h4>
    </template>
    <template #default>

      <!-- <el-row justify="space-between" v-for="item in list">
        <el-col :span="19" style="margin-bottom: 8px;">
          <el-text tag="B" truncated>任务标题123</el-text>
        </el-col>
        <el-col :span="5" style="text-align: right">
          <el-text size="small" type="info">07-11 15:33</el-text>
        </el-col>
        <el-col :span="22">
          <el-text size="small" style="display: inline-block !important;"
            truncated>这是一个什么样子的任务，非常好的任务哦，这是一个什么样子的任务，非常好的任务哦。。</el-text>
        </el-col>
        <el-col :span="2" style="text-align: right;cursor: pointer;">
          <el-icon>
            <ArrowRight />
          </el-icon>
        </el-col>
        <el-divider style="margin-top: 10px;" />
      </el-row> -->

      <el-row justify="space-between" v-for="item in list">
        <el-col :span="19" style="margin-bottom: 10px;">
          <el-text tag="B" truncated :type="item.errorLevel == '一般故障' ? '' : 'danger'">{{ item.errorTitle
          }}</el-text>
        </el-col>
        <el-col :span="5" style="text-align: right">
          <el-text tag="B" type="info">{{ item.gmtErrorDisplay }}</el-text>
        </el-col>
        <el-col :span="22">
          <el-icon :color="item.errorLevel == '一般故障' ? '' : 'red'" style="margin-right: 5px;">
            <BellFilled v-if="item.errorLevel == '一般故障'" />
            <WarningFilled v-else />
          </el-icon>
          <el-text size="small" style="display: inline-block !important;" truncated>{{ item.errorDesc }}</el-text>
        </el-col>
        <el-col :span="2" style="text-align: right;cursor: pointer;" @click="redirectToDetail(item.id)">
          <el-icon>
            <ArrowRight />
          </el-icon>
        </el-col>
        <el-divider style="margin-top: 10px;" />
      </el-row>
    </template>
    <template #footer>
      <div style="flex: auto">
        <!-- <el-button type="primary" @click="closedClick()">清空</el-button> -->

        <el-button @click="closedClick()">关闭</el-button>
      </div>
    </template>
  </el-drawer>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue'
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  WarnTriangleFilled,
} from '@element-plus/icons-vue'
import { getModelById, getNewModel, saveUserInfo } from '@/api/authUser'
import { isSuccess } from '@/utils/validate'
import { ElMessage, ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'
import { listData } from '@/api/stationError'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})


// const list = ref([1, 2, 3])
const list = ref([])
const init = async () => {
  list.value = await listData({ fixed: false })
}



const router = useRouter()
const redirectToDetail = (id) => {
  closedClick()
  router.push("/digital/error/detail/" + id)
  // router.push({ name: '站点故障记录', params: { id: id } })
}

const emits = defineEmits(['update:modelValue'])
const closedClick = () => {
  emits('update:modelValue', false)
}

watch(
  props,
  () => {
    if (props.modelValue) {
      init()
    }
  },
  {
    immediate: true
  }
)
</script>
