import layout from '@/layout'

export default {
  path: '/auth',
  component: layout,
  children: [
    {
      path: '/auth/dept',
      component: () => import('@/views/auth/dept/index')
    },
    {
      path: '/auth/user',
      component: () => import('@/views/auth/user/index')
    },
    {
      path: '/auth/role',
      component: () => import('@/views/auth/role/index')
    },
    {
      path: '/auth/permission',
      component: () => import('@/views/auth/permission/index')
    },
    {
      path: '/auth/position',
      component: () => import('@/views/auth/position/index')
    },
    {
      path: '/auth/request/login',
      component: () => import('@/views/auth/request/login'),
    },
    {
      path: '/auth/request/operate',
      component: () => import('@/views/auth/request/operate'),
    }
  ]
}
