import request from '@/utils/request'

const pre = '/station/error'

export const listPagedData = (params) => {
  return request({
    url: pre + '/listPagedData.json',
    method: 'POST',
    params: params
  })
}

export const listData = (params) => {
  return request({
    url: pre + '/listData.json',
    method: 'POST',
    params: params
  })
}

export const getCount = (params) => {
  return request({
    url: pre + '/getCount.json',
    method: 'POST',
    params: params
  })
}

export const getModelById = (id) => {
  return request({
    url: pre + '/getModelById.json',
    method: 'POST',
    params: {
      id
    }
  })
}

export const remove = (id) => {
  return request({
    url: pre + '/remove.json',
    method: 'POST',
    params: {
      id
    }
  })
}

export const getNewModel = () => {
  return request({
    url: pre + '/getNewModel.json',
    method: 'POST'
  })
}

export const save = (data) => {
  return request({
    url: pre + '/save.json',
    method: 'POST',
    data
  })
}





