<template>
  <el-drawer :model-value="modelValue" @close="closedClick" size="350px">
    <template #header>
      <el-row>
        <el-col :span="24" style="display: flex;align-items: center;">
          <el-upload style="width: 40px;height: 40px;margin-left: 10px;" :headers="headers" :show-file-list="false"
            :on-success="uploadSucess" :action="uploadUrl">
            <el-avatar shape="square" :size="40" :src="userAvatar" :error="loadAvatarError"></el-avatar>
          </el-upload>
          <span style="margin-left: 10px;">个人信息</span>
        </el-col>
      </el-row>
    </template>
    <template #default>
      <el-form style="margin-top: -10px;" label-width="80px" ref="formRef" :model="item" :rules="formRules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="登录名称">
              <el-input v-model="item.enLogin" disabled> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="部门名称">
              <el-input v-model="item.deptName" disabled> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户姓名" prop="cnName">
              <el-input v-model="item.cnName"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="item.mobile"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户密码">
              <el-input v-model="item.pwd1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="新的密码">
              <el-input v-model="item.pwd2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button type="primary" @click="saveClick()">保存</el-button>

        <el-button @click="closedClick()">关闭</el-button>
      </div>
    </template>
  </el-drawer>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue'
import { getModelById, getNewModel, saveUserInfo, updateAvatar } from '@/api/authUser'
import { isSuccess } from '@/utils/validate'
import { ElMessage, ElNotification } from 'element-plus'
import store from '@/store'


const item = ref(store.getters.userInfo)
const headers = ref({ Authorization: store.getters.token })
const uploadUrl = ref(process.env.VUE_APP_BASE_API + '/common/attach/receive.json')
const userAvatar = ref(item.value.avatar ? (process.env.VUE_APP_BASE_API + item.value.avatar) : require('@/assets/user.jpg'))
const appInfo = ref({})
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  selectIds: {
    type: String,
    required: true
  }
})






const uploadSucess = async (response) => {

  const avatar = '/common/attach/download/' + response.data;


  const res = await updateAvatar(avatar)
  if (isSuccess(res)) {
    item.value.avatar = avatar
    userAvatar.value = process.env.VUE_APP_BASE_API + item.value.avatar;
  }
}




const saveClick = () => {
  formRef.value.validate(async (valid) => {
    if (!valid) return
    const res = saveUserInfo({
      enLogin: item.value.enLogin,
      cnName: item.value.cnName,
      mobile: item.value.mobile,
      pwd1: item.value.pwd1,
      pwd2: item.value.pwd2,
      avatar: item.value.avatar
    })
    if (isSuccess(res)) {
      ElNotification.success('操作成功')
      closedClick()
    }
  })
}

const formRef = ref(null)
const formRules = ref({
  enLogin: [
    {
      required: true,
      trigger: 'blur',
      message: '登录名不能为空'
    }
  ],
  cnName: [
    {
      required: true,
      trigger: 'blur',
      message: '用户姓名不能为空'
    }
  ],
  mobile: [
    {
      required: true,
      trigger: 'blur',
      message: '手机号码不能为空'
    }
  ]
})
const emits = defineEmits(['update:modelValue'])
const closedClick = () => {
  emits('update:modelValue', false)
}

</script>
