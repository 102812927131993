import request from '@/utils/request'

const pre = '/auth/user'

export const getModelById = (id) => {
  return request({
    url: pre + '/getModelById.json',
    method: 'POST',
    params: {
      id
    }
  })
}

export const resetPwd = (id) => {
  return request({
    url: pre + '/resetPwd.json',
    method: 'POST',
    params: {
      id
    }
  })
}

export const remove = (id) => {
  return request({
    url: pre + '/remove.json',
    method: 'POST',
    params: {
      id
    }
  })
}


export const updateAvatar = (avatar) => {
  return request({
    url: pre + '/updateAvatar.json',
    method: 'POST',
    params: {
      avatar
    }
  })
}

export const getNewModel = (deptId) => {
  return request({
    url: pre + '/getNewModel.json',
    method: 'POST',
    params: {
      deptId
    }
  })
}

export const save = (data) => {
  return request({
    url: pre + '/save.json',
    method: 'POST',
    data
  })
}

export const saveUserInfo = (params) => {
  return request({
    url: pre + '/saveUserInfo.json',
    method: 'POST',
    params: params
  })
}

export const listPagedData = (params) => {
  return request({
    url: pre + '/listPagedData.json',
    method: 'POST',
    params: params
  })
}

export const listSelectPagedData = (params) => {
  return request({
    url: pre + '/listSelectPagedData.json',
    method: 'POST',
    params: params
  })
}

export const listPagedRequestLogin = (params) => {
  return request({
    url: pre + '/listPagedRequestLogin.json',
    method: 'POST',
    params: params
  })
}

export const buildEnLogin = (id, cnName) => {
  return request({
    url: pre + '/buildEnLogin.json',
    method: 'POST',
    params: {
      id,
      cnName
    }
  })
}

export const getELTree = (rootId) => {
  return request({
    url: pre + '/getELTree.json',
    method: 'POST',
    params: {
      rootId
    }
  })
}

export const listData = (params) => {
  return request({
    url: pre + '/listData.json',
    method: 'POST',
    params
  })
}
