import router from '@/router'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { publicRoutes } from '@/router'

// 白名单
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // if (store.state.user.token) {

  let browserTitle = '重庆鼎盛诺科技有限责任公司 '
  if (to.meta.browserTitle) {
    browserTitle = to.meta.browserTitle
  }
  document.title = browserTitle


  let whitePassed = false
  publicRoutes.forEach(p => {
    if (p.path != '' && p.path == to.path) {
      whitePassed = true
    }
  })

  if (whitePassed) {
    next()
  } else {

    // 快捷访问
    if (store.getters.token) {
      if (to.path === '/login') {
        next('/')
      } else {
        window.localStorage.setItem('path', to.path)
        // 判断用户资料是否获取
        // 若不存在用户信息，则需要获取用户信息
        if (!store.getters.hasUserInfo) {
          // 触发获取用户信息的 action，并获取用户当前权限
          const res = await store.dispatch('user/getUserInfo')
          if (res.success === false) {
            store.dispatch('user/logout')
            return
          }
          // 处理用户权限，筛选出需要添加的权限
          const filterRoutes = await store.dispatch('permission/filterRoutes', {
            menus: res.menus,
            groups: res.groups,
            elSubMenus: res.elSubMenus
          })

          // 利用 addRoute 循环添加
          filterRoutes.forEach((item) => {
            router.addRoute(item)
          })

          if (window.localStorage.getItem('sidebarOpened') == 'false') {
            store.commit('app/triggerSidebarOpened')
          }

          // 添加完动态路由之后，需要在进行一次主动跳转
          return next(to.path)
        }
        next()
      }
    } else {
      // 没有token的情况下，可以进入白名单
      next('/login')
    }
  }
})
