<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in breadcrumbData" :key="item.path">
        <!-- 不可点击项 -->
        <span v-if="index === breadcrumbData.length - 1" class="no-redirect">{{
          item.title
        }}</span>
        <!-- 可点击项 -->
        <a v-else class="redirect" @click.prevent="onLinkClick(item)">{{
          item.title
        }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
// 将来需要进行主题替换，所以这里获取下动态样式
const store = useStore()
const elSubMenus = store.getters.userInfo.elSubMenus
// eslint-disable-next-line
const linkHoverColor = ref(store.getters.cssVar.menuBg)



// 生成数组数据
const breadcrumbData = ref([])
const getBreadcrumbData = () => {

  const breadcrumbs = [];
  var path = route.matched[route.matched.length - 1].path
  var groupPath = '/' + path.split('/')[1]
  elSubMenus.forEach(p => {
    if (p.path == path) {
      breadcrumbs.push({ title: p.title, path: p.path })
    } else if (p.childList) {
      p.childList.forEach(n => {
        if (n.path == path) {
          breadcrumbs.push({ title: p.title, path: groupPath })
          breadcrumbs.push({ title: n.title, path: n.path })
        } else if (n.childList) {
          n.childList.forEach(o => {
            if (o.path == path) {
              breadcrumbs.push({ title: p.title, path: groupPath })
              breadcrumbs.push({ title: n.title })
              breadcrumbs.push({ title: o.title, path: o.path })
            }
          })

        }
      })
    }
  })

  breadcrumbData.value = breadcrumbs;
}


// 处理点击事件
const router = useRouter()
const onLinkClick = (item) => {
  router.push(item.path)
}



// 监听路由变化时触发

watch(
  route,
  () => {
    getBreadcrumbData()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }

  .redirect {
    color: #666;
    font-weight: 600;
  }

  .redirect:hover {
    // 将来需要进行主题替换，所以这里不去写死样式
    color: v-bind(linkHoverColor);
  }
}
</style>
