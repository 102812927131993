import layout from '@/layout'

export default {
  path: '/common',
  component: layout,
  redirect: '/common/request',
  children: [
    {
      path: '/common/gate/data',
      component: () => import('@/views/common/gate/data'),

    },
    {
      path: '/common/doc',
      component: () => import('@/views/common/doc/index'),

    },
    {
      path: '/common/sql',
      component: () => import('@/views/common/sql/index'),

    },
    {
      path: '/common/sql',
      component: () => import('@/views/common/sql/index'),

    },
    {
      path: '/common/tableInfo',
      component: () => import('@/views/common/tableInfo/index'),

    },
    {
      path: '/common/job',
      component: () => import('@/views/common/job/index'),

    },
    {
      path: '/common/post',
      component: () => import('@/views/common/post/index'),

    },
    {
      path: '/common/onlineUser',
      component: () => import('@/views/common/online/index'),

    },
    {
      path: '/common/monitorServer',
      component: () => import('@/views/common/monitor/server'),

    },
    {
      path: '/common/black',
      component: () => import('@/views/common/black/index'),

    },

    {
      path: '/common/code',
      component: () => import('@/views/common/code/index'),

    },
    {
      path: '/common/attach',
      component: () => import('@/views/common/attach/index'),

    },
  ]
}
