/**
 * 判断是否为外部资源
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 判断是否成功响应
 */
export function isSuccess(res) {
  if (!res) return false

  if (res.success !== undefined) {
    return res.success
  }
  if (res.status === 400) return false

  if (res.status === 404) return false
  return true
}
