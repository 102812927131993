<template>
  <div class="app-main" id="app-main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { isTags } from '@/utils/tags'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute()

/**
 * 监听路由变化
 */
const store = useStore()


watch(
  route,
  (to, from) => {
    if (!isTags(to.path)) return
    const { fullPath, path, meta, params, query } = to
    if (fullPath != '/dp/map') {
      store.commit('app/addTagsViewList', {
        fullPath,
        path,
        meta,
        params,
        query
      })
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px - 43px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 104px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
