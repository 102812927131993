import { createRouter, createWebHashHistory } from 'vue-router'
import AuthManageRouter from './modules/AuthManage'
import CommonManageRouter from './modules/CommonManage'
import SysManageRouter from './modules/SysManage'
import PoemManageRouter from './modules/PoemManage'

/**
 * 私有路由表 具备权限
 */
export const privateRoutes = [
  PoemManageRouter,
  AuthManageRouter,
  SysManageRouter,
  CommonManageRouter,
  {
    path: '/dp/map',
    component: () => import('@/views/dp/map')
  }
]

/**
 * 公开路由表 不具备权限
 */
export const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index')
  },
]

/**
 * 初始化路由表
 */
export function resetRouter() {
  // if (store.getters.userInfo && store.getters.userInfo.menus) {
  //   const menus = store.getters.userInfo.groups
  //   const routes = router.getRoutes()
  //   menus.forEach((menu) => {
  //     router.removeRoute(menu.permission_code)
  //   })
  // }
  //？没找到清空路由方法 通过强制刷新一次解决问题
}

var router = createRouter({
  history: createWebHashHistory(),
  routes: publicRoutes
})

export default router
