<template>
  <!-- 一级 menu 菜单 -->
  <el-menu :uniqueOpened="true" :default-active="activeMenu" :collapse="!$store.getters.sidebarOpened"
    :background-color="$store.getters.cssVar.menuBg" :text-color="$store.getters.cssVar.menuText"
    :active-text-color="$store.getters.cssVar.menuActiveText" router>
    <!-- <sidebar-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></sidebar-item> -->

    <sidebar-item v-for="item in elSubMenus" :menuData="item"></sidebar-item>


  </el-menu>


  <div style="height: 100px;"></div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SidebarItem from './SidebarItem'
import { filterRouters, generateMenus } from '@/utils/route'
import store from '@/store'

const elSubMenus = ref(store.getters.userInfo.elSubMenus)

const router = useRouter()

const routes = computed(() => {
  const routes = router.getRoutes()
  const filterRoutes = filterRouters(routes)
  const menus = generateMenus(filterRoutes)
  return menus
})

// 计算高亮 menu 的方法
const route = useRoute()
const activeMenu = computed(() => {
  const { meta, path } = route
  if (meta.activeMenu) {
    return meta.activeMenu
  }
  return path
})
</script>

<style lang="scss" scoped>
::v-deep {
  .el-sub-menu__title {
    padding-right: 0px !important;
  }
}
</style>
