import request from '@/utils/request'

export const login = (data) => {
  return request({
    url: '/login.json',
    method: 'POST',
    data
  })
}

export const getUserInfo = () => {
  return request({
    url: '/getUserInfo.json',
    method: 'POST'
  })
}
