// 专门处理权限路由的模块
import { publicRoutes, privateRoutes } from '@/router'
import layout from '@/layout'
export default {
  namespaced: true,
  state: {
    // 路由表：初始拥有静态路由权限
    routes: []
  },
  mutations: {
    /**
     * 增加路由
     */
    setRoutes(state, newRoutes) {
      // 永远在静态路由的基础上增加新路由
      state.routes = [...newRoutes]
    }
  },
  actions: {
    /**
     * 根据权限筛选路由
     */
    filterRoutes(context, { menus, groups, elSubMenus }) {
      const pathList = [];
      const nameList = [];
      const browserTitleList = [];
      const existPathList = [];
      elSubMenus.forEach(p => {
        if (p.path) {
          pathList.push(p.path)
          nameList.push(p.title)
          browserTitleList.push(p.browserTitle)
        } else if (p.childList) {
          p.childList.forEach(n => {
            if (n.path) {
              pathList.push(n.path)
              nameList.push(n.title)
              browserTitleList.push(n.browserTitle)
            } else if (n.childList) {
              n.childList.forEach(m => {
                if (m.path) {
                  pathList.push(m.path)
                  nameList.push(m.title)
                  browserTitleList.push(m.browserTitle)
                }
              })

            }
          })
        }
      })

      const myRoutes = [];
      const allRoutes = [...privateRoutes]
      allRoutes.forEach(p => {
        if (p.children) {
          const newChildren = [];
          p.children.forEach(n => {
            const index = pathList.indexOf(n.path);
            if (index >= 0) {
              existPathList.push(n.path)
              n.meta = { browserTitle: browserTitleList[index], title: nameList[index] }
              newChildren.push(n)
            }
          })
          if (newChildren.length > 0) {
            p.children = newChildren
            p.redirect = newChildren[0].path
            myRoutes.push(p)
          }
        } else if (p.path) {
          const index = pathList.indexOf(p.path);
          if (index >= 0) {
            existPathList.push(p.path)
            p.meta = { browserTitle: browserTitleList[index] }
            myRoutes.push(p)
          }
        }
      })



      console.log(myRoutes)

      let firstPath = '';
      for (var i = 0; i < pathList.length; i++) {
        if (existPathList.indexOf(pathList[i]) >= 0) {
          firstPath = pathList[i]
          break;
        }
      }
      const basicRoutes = [
        {
          path: '/',
          component: layout,
          redirect: firstPath,
        }, {
          path: '/:catchAll(.*)',
          redirect: firstPath
        }]
      const routes = [...basicRoutes, ...publicRoutes, ...myRoutes]
      context.commit('setRoutes', routes)
      return routes
    }
  }
}
