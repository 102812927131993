<template>
  <div class="">
    <div class="logo-container">
      <el-avatar
        class="logo"
        :size="logoHeight"
        shape="square"
        :src="logoPath"
      />
      <h1 class="logo-title" v-if="$store.getters.sidebarOpened">看图猜诗</h1>
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
import {} from 'vue'
const logoHeight = 35
const logoPath = require('@/assets/images/logo.png')
</script>

<style lang="scss" scoped>
.logo-container {
  height: v-bind(logoHeight) + 'px';
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-title {
    margin-left: 12px;
    color: #fff;
    font-weight: 600;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }

  .logo {
    border-radius: 10px;
  }
}
</style>
