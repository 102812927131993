<template>
  <el-menu-item :index="menuInfo.path" :route="{ path: menuInfo.path, state: menuInfo.state }" v-if="menuInfo.visible">
    <svg-icon :icon="menuInfo.icon"></svg-icon>
    <span>{{ menuInfo.title }}</span>
  </el-menu-item>
</template>
<script setup>
import { defineProps, ref } from 'vue'
const props = defineProps({
  menuInfo: {
    type: Object,
    required: true
  }
})

const abc = ref('<Search />')
</script>

<style lang="scss" scoped></style>
