import layout from '@/layout'

export default {
  path: '/poem',
  component: layout,
  children: [
    {
      path: '/poem/poem',
      component: () => import('@/views/poem/poem')
    },
    {
      path: '/poem/banner',
      component: () => import('@/views/poem/banner')
    },
    {
      path: '/poem/category',
      component: () => import('@/views/poem/category')
    },
    {
      path: '/poem/user',
      component: () => import('@/views/poem/user')
    }
  ]
}
