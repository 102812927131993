import axios from 'axios'
import store from '@/store'
import { ElMessage, ElLoading, ElNotification } from 'element-plus'
import { isCheckTimeout } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
})
let loading = null
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在这个位置需要统一的去注入token
    if (store.getters.token) {
      if (isCheckTimeout()) {
        // 登出操作
        store.dispatch('user/logout')
        return Promise.reject(new Error('token 失效'))
      }
      // 如果token存在 注入token
      config.headers.Authorization = `${store.getters.token}`
    }

    if (
      config.url.indexOf('save.json') > 0 ||
      config.url.indexOf('/download') > 0
    ) {
      loading = ElLoading.service({
        text: '加载中',
        background: 'rgba(0, 0, 0, 0.7)',
        target: '.app-main'
      })
    }
    // 配置接口国际化
    config.headers['Accept-Language'] = store.getters.language
    return config // 必须返回配置
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (loading) loading.close()

    if (response.status === 204) {

      ElNotification.error("no content 204")
      return response
    }


    if (response.status === 200 && response.data.size) {
      return response
    }


    const { success, message, data } = response.data
    // 要根据success的成功与否决定下面的操作,为空是为了兼容异步任务返回空
    if (success || response.data == '') {
      return data
    } else {

      debugger
      // 业务错误
      ElMessage.error(message) // 提示错误消息
      return response.data
    }
  },
  (error) => {


    // 处理 token 超时问题
    if (
      error.response &&
      ((error.response.data && error.response.data.code === 401) ||
        error.response.status === 403)
    ) {
      // token超时
      store.dispatch('user/logout')
      return
    }

    let message = error.message

    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
      if (error.response.data.status === 404) {
        message = JSON.stringify(error.response.data)
      }
    }
    ElMessage.error(message)
    if (error.config.url && error.config.url.indexOf('.json') > 0) {
      return error.response.data
    } else {
      return Promise.reject(error)
    }

    // 提示错误信息
  }
)

export const handleDownload = async (id) => {
  const res = await axios({
    url: process.env.VUE_APP_BASE_API + '/common/attach/download/' + id,
    responseType: 'blob'
  })
  if (res.data.size > 0) {
    const blob = new Blob([res.data.data], { type: res.data.data.type })
    const objectUrl = URL.createObjectURL(blob)
    const contentDisposition = res.data.headers['content-disposition']
    const fileName = contentDisposition
      .substring(contentDisposition.indexOf('='))
      .replace('=', '')
    if ('msSaveOrOpenBlob' in navigator) {
      // ie使用的下载方式
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.download = decodeURI(fileName)
      a.href = objectUrl
      a.click()
    }
  } else {
    console.log('文件不存在!')
  }
}

export default service
